@font-face {
  font-family: 'Pintassilgo';
  src: url('./fonts/PintassilgoPrints.otf') format("opentype");
}

@font-face {
  font-family: 'ManuskriptRegular';
  src: url('./fonts/ManuskriptAntD-Reg.otf') format("opentype");
}

@font-face {
  font-family: 'ManuskriptItalic';
  src: url('./fonts/ManuskriptAntD-Ita.otf') format("opentype");
}

@font-face {
  font-family: 'BrushTip';
  src: url('./fonts/brushTipTexe.ttf') format("opentype");
}

@font-face {
  font-family: 'Windlass';
  src: url('./fonts/WindlassExtended-Regular_0.otf') format("opentype");
}


body {
    padding: 0;
    margin: 0;
    font-family: 'Arial', sans-serif;
    background: transparent;
}

* {
  box-sizing: border-box;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}

.dashboard {
    display: block;
    box-sizing: border-box;
    width: 100vw;
    padding: 1em 2em;
}

.flexContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.col2 {
  flex: 1 0 50% !important;
  flex-wrap: wrap;
}

.column {
    border: 1px solid #ccc;
    flex: 1;
    padding: 1em;
}

.column.grid {
  position: relative;
  min-width: 16.666%;
}

.editRowLabel {
  transform-origin: top left;
  transform: rotate(-90deg) translateX(-113px) translateY(-35px);
  width: 130px;
  height: 18px;
  position: absolute;
  text-align: center;
  font-size: .7em;
  font-weight: bold;
  padding: .3em .8em;
  background-color: #333;
  color: #fff;
}

.controls {
  width: 20%;
}

.riddleEasy {
  background-color: rgb(170,242,145) !important;
}

.riddleMedium {
  background-color: rgb(242,186,145) !important;
}

.riddleHard {
  background-color: rgb(242,145,147) !important;
}

.setRiddle {
  border: 1px solid #ccc;
  background: #efefef;
  color: #333 !important;
  text-transform: uppercase;
}

.setRiddle.active {
  background-color: #efefef !important;
}

.modal {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100vw;
}

.modal img {
  position: relative;
  height: 100vh;
  left: 50%;
  transform: translateX(-50%);
}

.modal .coords {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 200;
  color: #fff;
  padding: 1em;
  background-color: rgba(0,0,0,.5);
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.react-transform-wrapper {
    height: 1080px !important;
    overflow: visible;
}

.mapContainer {
    display: block;
    overflow: hidden;
    position: relative;
    width: 1920px;
    height: 1080px;
    background-color: #ccc;
}

.location {
    position: absolute;
    top: 16%;
    left: 24%;
    width: 100px;
    height: 100px;
    z-index: 100;
    transform: translateX(-50%) translateY(-50%);
    background-color: rgba(255,0,0,.5);
}

.box {
    width: calc(100vw * .5);
    height: calc(100vw * .288);
    border: 2px solid #ff0000;
    background-color: rgba(255,0,0,.3);
    position: absolute;
}

.mapX {
    display: block;
    position: absolute;
    transform-origin: top left;
}

.mapX img {
  transform: translateX(-50%) translateY(-50%);
  width: 110px;
}

td:first-child {
    width: 50%;
    font-weight: bold;
}

td.riddleControls button {
  width: 90% !important;
}

.resetTimer button {
  width: auto !important;
}

td {
    height: 40px;
    text-align: left;
}

td:last-child {
    text-align: center;
}

input {
    padding: 1em;
    width: 100%;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -o-user-select: text;
    user-select: text;
}

button {
    border: 0;
    padding: 1em;
    font-weight: bold;
    color: #fff;
    background-color: #666;
    cursor: pointer;
    margin-right: 5px;
}

button.activeDB {
  background-color: rgb(150,0,0);
  color: #fff;
}

button.active {
  background-color: #20af00;
  color: #fff;
}

.alert0 {
    
}

.alert1 {
    background-color: rgb(255,209,210);
}

.nodeControls button {
  width: 140px;
  margin-right: 10px;
}

.revealAnswer {
  background-color: #0084af;
}

.revealMap {
  background-color: #20af00;
}

.revealMapCut {
  background-color: #126000;
}

.increment {
    background-color: #20af00;
}

.decrement {
    background-color: #af0e11;
}

.switch {
  width: 80px;
  height: 30px;
  border-radius: 100px;
  padding: 5px;
  display: flex;
  cursor: pointer;
  margin: 0 auto;
}

.switch.on {
  background-color: #22cc88;
  justify-content: flex-end;
}

.switch.off {
  background-color: #dddddd;
  justify-content: flex-start;
}

.switch div {
  width: 30px;
  height: 20px;
  background-color: #ffffff;
  border-radius: 200px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.02);
}
