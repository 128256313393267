

.screenContainer {
    display: block;
    overflow: hidden;
    position: relative;
    width: 1920px;
    height: 1080px;
    padding: 0;
    margin: 1px 0px 0px 1px;
    letter-spacing: 1px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 15px rgba(255,229,215,.8);
    font-family: 'Windlass';
    font-size: 3em;
}

.screenContainer.evil {
    color: #2e0502;
    /* background-color: #792626; */
}

.screenContainer.good {
    color: rgba(79,36,34,.9);
    /* background-color: #cca179; */
}

.host.evil {
    color: #fff;
}

.host.good {
    color: #4f2422;
}

.host, .evilCaptain, .goodCaptain {
    font-family: 'Pintassilgo';
    position: absolute;
    left: 3%;
    text-align: left;
    white-space: nowrap;
    font-size: 1.1em;
    text-shadow: none;
    width: 100%;
}

.host {
    top: 50%;
}

.evilCaptain {
    top: 83%;
    color: #fff;
}

.goodCaptain {
    top: 83%;
}

.timeRemaining {
    position: absolute;
    left: 58%;
    top: 3%;
    text-align: center;
    transform: translateX(-50%);
}

.timeRemaining .title {
    font-size: .9em;  
    line-height: 1.3em; 
}

.timeRemaining .value {
    font-size: 1.7em;   
}

.chest {
    position: absolute;
    top: 10px;
    left: -75px;
    opacity: .8;
}

.boats.value {
    font-size: .5em;   
    line-height: 1em !important;
    width: 300px;
    transform: translateY(10px);
}

.boats.value div {
    display: inline-block;
}

.boatX {
    position: absolute;
    z-index: 10;
    bottom: -5px;
}

.leftColEvil {
    position: absolute;
    left: 77%;
    top: 4%;
    text-align: center;
    transform: translateX(-50%);
    font-size: 1.1em;
    width: 300px;
}

.rightColEvil {
    position: absolute;
    left: 90%;
    top: 4%;
    text-align: center;
    transform: translateX(-50%);
    font-size: 1.1em;
    width: 300px;
}

.leftColGood {
    position: absolute;
    left: 77%;
    top: 4%;
    text-align: center;
    transform: translateX(-50%);
    font-size: 1.1em;
    width: 200px;
}

.rightColGood {
    position: absolute;
    left: 90%;
    top: 4%;
    text-align: center;
    transform: translateX(-50%);
    font-size: 1.1em;
    width: 300px;
}

.title {
    line-height: 1.2em;   
    font-size: .7em;
}

.leftColGood .value, .rightColGood .value, .leftColEvil .value, .rightColEvil .value {
    line-height: 2em;   
}

.drops img {
    margin: 0 2px;
    opacity: .8;
}

.dropRow {
    font-size: 1em;
    white-space: nowrap;
}

.dropRow img {
    width: 35px;
    transform: translateY(0);
}

.scoreboardDataRow:first-child {
    margin-top: 14.5%;
    color: #744136;
}

.scoreboardDataRow:nth-child(2) {
    color: #6b3a31;
}

.scoreboardDataRow:nth-child(3) {
    color: #61332b;
}

.scoreboardDataRow:nth-child(4) {
    color: #552925;
}

.scoreboardDataRow:nth-child(5) {
    color: #49211f;
}

.scoreboardDataRow:nth-child(6) {
    color: #3f1b1a;
}

.scoreboardDataRow {
    width: 67%;
    left: 16.25%;
    display: block;
    position: relative;
    font-size: 1.6em;
}

.scoreboardDataRow .rowTitle {
    width: 50%;
    display: inline-block;
}

.scoreboardDataRow .rowValue {
    width: 50%;
    display: inline-block;
    text-align: right;
    color: #401d1f;
}

.scoreboardDataRow .rowValue div {
    display: inline-block;
}

.scoreboardDataRow .rowValue .boatX {
    top: 8px;
}