body {
    overflow: hidden;
}

.screenContainer.riddle {
    text-shadow: none;
    position: absolute;
    top: 0;
    left: 0;
}

.pageBg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.chestsOverlay {
    position: absolute;
    left: 64px;
    top: 320px;
    z-index: 10;
}

.riddleSingle {
    font-family: 'BrushTip';
    display: block;
    margin: 150px auto;
    width: 1050px;
    height: 800px;
    text-align: center;
    font-size: 1em;
    position: relative;
    color: rgb(55,26,23);
}

.riddleSingle h2 {
    position: absolute;
    top: 100px;
    width: 100%;
    font-family: 'Windlass';
    text-align: center;
    text-transform: uppercase;
    font-size: 1.2em;
}

.riddleSingle .questionAnswer {
    position: relative;
    top: 270px;
    height: 500px;
    padding: 0 1em;
}

.riddleSingle .question {
    position: relative;
    width: 100%;
    font-family: 'Windlass';
    text-align: center;
    padding-bottom: 1em;
}

.riddleSingle .answer {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    font-family: 'Pintassilgo';
    font-size: 1.35em;
    margin: 1em 0;
}

.riddlesAll {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 180px 120px 0 135px;
    color: #501f1d;
    border: 1px solid #501f1d;
}

.riddlesAll > div.header {
    font-size: .46em;  
    line-height: .95em; 
    height: 70px;
    padding: 0;
}

.riddlesAll > div {
    border: 2px solid #501f1d;
    width: 25%;   
    text-align: center;
    height: 235px;
}

.riddlesAll .questionAnswer {
    padding: .4em .5em;
    position: relative;
    letter-spacing: -.1px
}

.riddlesAll .questionAnswer img {
    position: absolute;
    width: 15%;
    opacity: .3;
    top: 50%;
    left: 43%;
    transform: translateY(-52%);
}

.riddlesAll .question {
    position: relative;
    width: 100%;
    font-family: 'BrushTip';
    text-align: center;
    font-size: .53em;
    line-height: 1.5em;
}

.riddlesAll .answer {
    position: absolute;
    width: 100%;
    bottom: 15px;
    left: 0;
    font-family: 'Pintassilgo';
    margin: 0;
    font-size: .5em;
    line-height: 1.2em;
}