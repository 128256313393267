body {
    padding: 0;
    margin: 0;
}

.react-transform-wrapper {
    height: 1080px !important;
    overflow: visible;
}

.mapContainer {
    display: block;
    overflow: hidden;
    position: relative;
    width: 1920px;
    height: 1080px;
}

.location {
    position: absolute;
    top: 16%;
    left: 24%;
    width: 100px;
    height: 100px;
    z-index: 100;
    transform: translateX(-50%) translateY(-50%);
    background-color: rgba(255,0,0,.5);
}